/* Portfolio Items Container */
.po_items_ho {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px;
    justify-content: center;
}

/* Responsive Design */
@media (max-width: 992px) { /* Tablets */
    .po_items_ho {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
}

@media (max-width: 768px) { /* Mobile */
    .po_items_ho {
        grid-template-columns: repeat(1, 1fr); /* 1 item per row */
    }
}

.po_item {
    width: 100%;
    aspect-ratio: 16 / 9; /* Maintains landscape aspect ratio */
    overflow: hidden;
    position: relative;
    text-align: center;
    background: var(--secondary-color);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.po_item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Keeps aspect ratio consistent */
}

/* Hover effect */
.po_item .content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--overlay-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: var(--secondary-color);
}

.po_item:hover .content {
    opacity: 1;
}

/* Modal Popup */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--primary-color);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 500px;
}

.modal-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
}
